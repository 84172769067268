import React, { Component } from 'react';

export default class Resume extends Component {
  // Function to calculate the duration between start and end date
  calculateDuration(startDate, endDate) {
    const start = new Date(startDate.toLowerCase());
    const end = endDate ? new Date(endDate.toLowerCase()) : new Date();

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();

    if (days < 0) {
      months -= 1;
      days += 30; // Adjust days (assuming months have approximately 30 days)
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    // Calculate total duration in months and round up to the nearest whole month
    const totalDuration = years * 12 + months + days / 30;
    return Math.ceil(totalDuration);
  }

  // Function to convert total duration in months to years and months format
  formatDurationInYearsMonths(totalMonths) {
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    // Create an array to hold non-zero parts of the duration
    let parts = [];

    // Add years to the parts array if years is non-zero
    if (years > 0) {
        parts.push(`${years}y`);
    }

    // Add months to the parts array if months is non-zero
    if (months > 0) {
        parts.push(`${months}m`);
    }

    // Join the parts with a space and return the resulting string
    return parts.join(' ');
  }

  renderSkillsList() {
    const { resumeData } = this.props;

    const threshold = 30;
    // Get the maximum duration across all skills
    const maxDuration = resumeData.skills.reduce((max, skill) => {
      const duration = this.calculateDuration(skill.skillstart, skill.skillstop);
      return Math.max(max, duration);
    }, 0);

    // Scale the maximum duration to 95% width
    const maxWidthPercentage = 95;

    // Map through the skills and render list items
    return resumeData.skills.map((item, index) => {
      const duration = this.calculateDuration(item.skillstart, item.skillstop);

      // Calculate width as a percentage of the maximum duration, scaled to 95%
      const widthPercentage = (duration / maxDuration) * maxWidthPercentage;

      // Determine closing symbol based on skillstop
      const closingSymbol = item.skillstop ? (
        <em>
          <div className="pause">
            <img className="pause-pic" src="images/pause-button-png-24.png" alt="" />
          </div>
        </em>
      ) : (
        <em>
          <div className="infinity">
            <img
              className="inf-pic"
              src="images/012f7bd53633f5a6e78e60ea08948c55-minimalist-infinity-logo-by-vexels.png"
              alt=""
            />
          </div>
        </em>
      );

      // Render the list item
      return (
        <li key={index}>
          <span
            className={`bar-expand ${item.skillname.toLowerCase()} ${widthPercentage < threshold ? 'small-font' : ''}`}
            style={{ width: `${widthPercentage}%` }}
          >
            {this.formatDurationInYearsMonths(duration)}
          </span>
          <em>{item.skillname}</em>
          {closingSymbol}
        </li>
      );
    });
  }

  render() {
    const { resumeData } = this.props;

    return (
      <section id="resume">
        {/* Education Section */}
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            {resumeData.education.map((item, index) => (
              <div key={index} className="row item">
                <div className="twelve columns">
                  <h3>{item.UniversityName}</h3>
                  <p className="info">
                    {item.type}<br />
                    {item.specialization}
                    <span>&#8212;</span> {item.YearOfPassing}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Work Section */}
        <div className="row work">
          <div class="three columns header-col">
            <h1><span>Work</span></h1>
          </div>
          <div class="nine columns main-col">
            {resumeData.work.map((item, index) => (
              <div key={index} className="row item">
                <div className="twelve columns">
                  <h3>{item.CompanyName} <a href={item.Link} target="_blank"><i className={item.className}></i></a><br />{item.job}</h3>
                  <p className="info">
                    {item.specialization}
                    <br /><em>From {item.Starting} till {item.Leaving}</em>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Skills Section */}
        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">
                {this.renderSkillsList()}
              </ul>
              <h5>Other technologies that I've worked with:</h5>
              <h6>Kafka, Redis, SBT, Git, HBase, Nginx, Kibana, Grafana</h6>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
